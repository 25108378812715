import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "elements/Form/services/form"
import { formSchema } from "../utils/formSchema"
import { medEnsureSteps } from "../../utils/medEnsureSteps"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"

let { parseFormField } = require("../../../../services/airtable")

const EmployeeInformation = ({ pageContext, location }) => {
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  let medicines = medEnsureState?.medicines
  let hasColdChainSKU = medicines.find((medicine) =>
    medicine?.skuTags?.includes?.("Cold chain")
  )

  const handleSubmitMedEnsureEmployeeInformation = (values) => {
    medEnsureDispatch({
      type: "SAVE_EMPLOYEE_INFORMATION",
      payload: {
        ...values,
      },
    })
    medEnsureDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...medEnsureState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      currentStep={4}
      totalSteps={4}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      steps={medEnsureSteps}
      location={location}
      path={pageContext?.module.path}
      hasMobileStepper={true}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{ ...medEnsureState }}
          onSubmit={handleSubmitMedEnsureEmployeeInformation}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                >
                  {(!!section?.message ||
                    (section?.section?.includes("Delivery") &&
                      !!hasColdChainSKU)) && (
                    <Message color={section?.messageColor || "light"}>
                      {section?.message}
                      {section?.section?.includes("Delivery") &&
                      !!hasColdChainSKU ? (
                        <p className="mt-2">
                          We only deliver cold chain medicines within Metro
                          Manila for now. We will inform you of any positive
                          changes. Thank you.
                        </p>
                      ) : null}
                    </Message>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values: values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ButtonGroup
                back={{
                  link: pageContext.backPath,
                }}
                next={{
                  label: `Next: ${pageContext.module.next}`,
                  link: pageContext.nextPath,
                  type: "submit",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default EmployeeInformation
