import {
  faPrescription,
  faSearch,
  faFileMedicalAlt,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons"

export const medEnsureSteps = [
  {
    pathname: "medensure/request/upload",
    label: "Prescription Upload",
    icon: faPrescription,
  },
  {
    pathname: "medensure/request/order",
    label: "Medicine Request",
    icon: faSearch,
  },
  {
    pathname: "medensure/request/health-survey",
    label: "Health Survey",
    icon: faFileMedicalAlt,
  },
  {
    pathname: "medensure/request/employee-information",
    label: "Employee Information",
    icon: faUserEdit,
  },
]
